export interface ItemTypeLabel {
  id: number;
  is_default: boolean;
  name: string;
}

export interface Organisation {
  id: number;
  name: string;
}

export interface ItemType {
  billing_name?: string;
  id: number;
  label: ItemTypeLabel;
  name: string;
  organisations: Organisation[];
  type: string;
  settings?: {
    use_default_weight: boolean;
    use_default_volume: boolean;
    use_default_dimensions: boolean;
    default_weight: string;
    default_weight_unit: string;
    default_volume: string;
    default_volume_unit: string;
    default_length: string;
    default_length_unit: string;
    default_width: string;
    default_width_unit: string;
    default_height: string;
    default_height_unit: string;
  };
}

export const ITEM_TYPE_CATEGORY = {
  container: 'container',
};
