import Button from '@material-ui/core/Button';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getValue } from '@yojee/helpers/access-helper';
import { Dialog, Input, ManageTable, ReactSelect, TextArea, useTable } from '@yojee/ui/base';
import Checkbox from '@yojee/ui/base/furnitures/Checkbox';

import {
  ALL_READ_PERMISSIONS,
  ALL_WRITE_PERMISSIONS,
  CONST_VARIABLE,
  DISABLED_WRITE_PERMISSIONS,
  MAX_LENGTH,
  MUST_HAVE_READ_PERMISSIONS,
} from './constants';
import OrganisationalUnitMultiSelect from './OrganisationalUnitMultiSelect';
import useRow from './useRow';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  bold: {
    fontWeight: 600,
  },
  errorPermission: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  noHorizontalScrolling: {
    '& table': {
      minWidth: '100%',
    },
  },
  iconExpand: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  textExpand: {
    paddingLeft: theme.spacing(1),
  },
  childExpand: {
    paddingLeft: theme.spacing(4),
  },
  collapseControlButton: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    height: theme.spacing(5.875),
    '& .manage-MuiButton-endIcon': {
      marginLeft: 0,
      '& svg': {
        fontSize: '1.5rem',
      },
    },
  },
  gridItemPaddingTopZero: {
    '&.manage-MuiGrid-item': {
      paddingTop: 0,
    },
  },
  gridItemPaddingZero: {
    '&.manage-MuiGrid-item': {
      padding: 0,
    },
  },
  selectOUTitle: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
  },
  gridCheckboxBlankOU: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const headCells = [
  { id: 'name', numeric: false, label: <Trans>Permission</Trans>, width: '34%' },
  { id: 'checkBoxRead', numeric: false, label: <Trans>View</Trans>, width: '33%' },
  { id: 'checkBoxEdit', numeric: false, label: <Trans>Edit</Trans>, width: '33%' },
];

const roleOUAssociationOptions = [
  { value: 'allOrganisationalUnits', label: <Trans>All OUs</Trans> },
  { value: 'specificOrganisationalUnits', label: <Trans>Specific OUs</Trans> },
];

const ModifyRoleDialog = ({ role = undefined, onClose, onSave, open, allowedCompanyIds, ...others }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const transferListRef = useRef(null);
  const errorTableRef = useRef(null);
  const [data, setData] = useState(
    role
      ? {
          name: role.name,
          company_id: role.company_id,
          description: role.description,
        }
      : {
          name: undefined,
          company_id: undefined,
          description: undefined,
        }
  );

  const [roleOUAssociationType, setRoleOUAssociationType] = useState(
    role && (!role.assignedToAllOUs || role.organisational_units.length > 0)
      ? 'specificOrganisationalUnits'
      : 'allOrganisationalUnits'
  );

  const [selectedOrganisationalUnits, setSelectedOrganisationalUnits] = useState(
    role ? role.organisational_units.map(({ id, name }) => ({ value: id, label: name })) : []
  );

  const [accessibleBlankOU, setAccessibleBlankOU] = useState(role ? role.assignedToBlankOU : true);
  const [accessibleAllOUs, setAccessibleAllOUs] = useState(role ? role.assignedToAllOUs : true);

  const [missingOUError, setMissingOUError] = useState(false);

  const [permissions, setPermissions] = useState(role ? role.permissions.map((p) => p.name) : []);

  const [isInProgress, setIsInProgress] = useState(false);

  const isActionInProgress = useSelector(
    (state) =>
      getValue(state, 'users.inProgress.requestCreateRole') || getValue(state, 'users.inProgress.requestUpdateRole')
  );
  const errorMessage = useSelector((state) => state.users?.errorMessage);
  const successMessage = useSelector((state) => state.users?.successMessage);
  const relatedCompanies = useSelector((state) => state.auth?.relatedCompanies || []);
  const availablePermissions = useSelector((state) => state.users?.permissions?.list || []);
  const [error, setError] = useState({
    [CONST_VARIABLE.NAME]: '',
    [CONST_VARIABLE.COMPANY]: '',
    [CONST_VARIABLE.DESCRIPTION]: '',
    [CONST_VARIABLE.PERMISSION]: '',
  });
  const setErrorHelper = (key, value) => {
    setError((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  useEffect(() => {
    if (isActionInProgress && !isInProgress) {
      setIsInProgress(true);
    } else if (isInProgress) {
      if (successMessage) {
        handleClose();
        setIsInProgress(false);
      } else if (errorMessage) {
        setIsInProgress(false);
      }
    }
  }, [isActionInProgress]);

  const handleClose = () => {
    setData(undefined);
    onClose();
  };

  const isValid = () => {
    let valid = true;
    let firstRef = null;
    const checkIfRequired = (key) => {
      valid = false;
      setErrorHelper(key, 'This field is required');
      if (!firstRef) firstRef = inputRef;
    };
    if (!data.name) checkIfRequired(CONST_VARIABLE.NAME);
    if (!data.company_id) checkIfRequired(CONST_VARIABLE.COMPANY);
    firstRef?.current?.scrollIntoView({ behavior: 'smooth' });

    if (
      roleOUAssociationType === 'specificOrganisationalUnits' &&
      selectedOrganisationalUnits.length === 0 &&
      !accessibleBlankOU
    ) {
      setMissingOUError(true);
      valid = false;

      if (!firstRef) {
        transferListRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return valid;
  };

  const handleSave = () => {
    if (!isValid()) return false;
    const _accessMap = {};
    availablePermissions.forEach((p) => {
      _accessMap[p.name] = permissions.includes(p.name);
    });
    MUST_HAVE_READ_PERMISSIONS.forEach((p) => {
      _accessMap[p] = true;
    });
    onSave(role && role.id, {
      ...data,
      access_map: _accessMap,
      organisational_unit_ids:
        roleOUAssociationType === 'specificOrganisationalUnits'
          ? selectedOrganisationalUnits.map(({ value }) => value)
          : [],
      assigned_to_blank_ou: accessibleBlankOU,
      assigned_to_all_ous: accessibleAllOUs,
    });
  };

  const options = relatedCompanies
    .filter((c) => allowedCompanyIds.includes(c.company_id))
    .map((c) => {
      return {
        value: c.company_id,
        label: `${c.company_name} - ${c.company_id}`,
      };
    });

  const { rows } = useRow({ data, permissions, setPermissions, setErrorHelper });
  const { selected, isSelected, handleClick, handleSelectAllClick } = useTable({ rows });

  const selectedReadPermissionCount = permissions.filter((p) => ALL_READ_PERMISSIONS.includes(p)).length;
  const selectedWritePermissionCount = permissions.filter((p) => ALL_WRITE_PERMISSIONS.includes(p)).length;

  // In some cases, the permissions user has also includes the disabled write permissions so we need to
  // substract the disabled write permissions that user has from the total disabled write permissions
  // to get the available write permissions
  const disabledWritePermissionsUserHas = DISABLED_WRITE_PERMISSIONS.filter((p) => permissions.includes(p));
  const remainedDisabledWritePermissions = DISABLED_WRITE_PERMISSIONS.length - disabledWritePermissionsUserHas.length;
  const availableWritePermissionCount = ALL_WRITE_PERMISSIONS.length - remainedDisabledWritePermissions;

  const tableConfig = {
    id: 'YJTableAllDrivers',
    numSelected: selected.length,
    rowCount: rows.length,
    columns: headCells,
    data: rows,
    showCheckBox: false,
    isSelected: isSelected,
    onRowClick: handleClick,
    onSelectAllClick: handleSelectAllClick,
    rowActions: [],
    className: classes.noHorizontalScrolling,
    isCollapsible: true,
    isCollapsedByDefault: true,
    getCollapseControlRow: (isExpanded, setIsExpanded) => [
      {
        key: 'totalPermission',
        component: (
          <Button
            className={classes.collapseControlButton}
            variant="text"
            color="primary"
            endIcon={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Trans>Total</Trans>
          </Button>
        ),
      },
      {
        key: 'readPermission',
        component: (
          <span>
            {selectedReadPermissionCount} / {ALL_READ_PERMISSIONS.length}
          </span>
        ),
      },
      {
        key: 'writePermission',
        component: (
          <span>
            {selectedWritePermissionCount} / {availableWritePermissionCount}
          </span>
        ),
      },
    ],
  };

  return (
    <Dialog
      onClose={handleClose}
      title={role ? <Trans>Edit Role</Trans> : <Trans>Create Role</Trans>}
      onSave={handleSave}
      data={data}
      open={open}
      dividers="paper"
      isLoading={isActionInProgress}
      {...others}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={6} ref={inputRef}>
          <Input
            fullWidth
            label={<Trans>Name</Trans>}
            required={true}
            disabled={data?.name?.toLowerCase() === 'admin'}
            value={(data && data.name) || ''}
            onChange={(event) => {
              setData({ ...data, name: event.target.value });
              setErrorHelper(CONST_VARIABLE.NAME);
            }}
            helperText={error[CONST_VARIABLE.NAME]}
            error={error[CONST_VARIABLE.NAME]?.length > 0}
          />
        </Grid>
        <Grid item xs={6}>
          <ReactSelect
            value={
              (data &&
                data.company_id && {
                  value: data.company_id,
                  label: options.find((option) => option.value === data.company_id)?.label,
                }) ||
              ''
            }
            options={options}
            onChange={({ value }) => {
              setData({
                ...data,
                company_id: value,
              });
              setErrorHelper(CONST_VARIABLE.COMPANY);
            }}
            isMulti={false}
            label={<Trans i18nKey="companyWorkspace">Company workspace</Trans>}
            required={true}
            helperText={error[CONST_VARIABLE.COMPANY]}
            error={error[CONST_VARIABLE.COMPANY]?.length > 0}
            disabled={!!role}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            fullWidth
            label={<Trans>Description</Trans>}
            required={false}
            disabled={false}
            multiline
            minRows={1}
            maxRows={9}
            value={(data && data.description) || ''}
            maxLength={MAX_LENGTH}
            onChange={(event) => {
              setData({ ...data, description: event.target.value.substring(0, MAX_LENGTH) });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ManageTable
            searchInput={null}
            actionBtnsShow={false}
            actionBtns={null}
            pagination={null}
            tableConfig={tableConfig}
          />
        </Grid>
        {error[CONST_VARIABLE.PERMISSION] && (
          <MuiFormHelperText ref={errorTableRef} className={classes.errorPermission} error={true}>
            {error[CONST_VARIABLE.PERMISSION]}
          </MuiFormHelperText>
        )}
        <Grid item xs={12} className={classes.gridItemPaddingTopZero}>
          <Typography className={classes.selectOUTitle}>
            <Trans>Access to the OUs</Trans>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReactSelect
                isMulti={false}
                options={roleOUAssociationOptions}
                value={roleOUAssociationOptions.find((option) => option.value === roleOUAssociationType)}
                onChange={({ value }) => {
                  setRoleOUAssociationType(value);
                  if (value === 'specificOrganisationalUnits') {
                    setAccessibleAllOUs(false);
                  }
                  if (value === 'allOrganisationalUnits') {
                    setAccessibleBlankOU(true);
                    setAccessibleAllOUs(true);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridCheckboxBlankOU}>
              <Checkbox
                checked={accessibleBlankOU}
                disabled={roleOUAssociationType === 'allOrganisationalUnits'}
                onChange={(e) => {
                  setAccessibleBlankOU(e.target.checked);
                  if (e.target.checked) {
                    setMissingOUError(false);
                  }
                }}
                color="primary"
                label={<Trans>Blank OU</Trans>}
              />
            </Grid>
          </Grid>
        </Grid>
        {roleOUAssociationType === 'specificOrganisationalUnits' && (
          <Grid ref={transferListRef} item xs={12} className={classes.gridItemPaddingZero}>
            <OrganisationalUnitMultiSelect
              missingOUError={missingOUError}
              value={selectedOrganisationalUnits}
              onChange={(newValue) => {
                setSelectedOrganisationalUnits(newValue);
                if (newValue.length > 0) setMissingOUError(false);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default ModifyRoleDialog;
