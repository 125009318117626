export const MAX_LENGTH = 300;

export const CONST_VARIABLE = {
  NAME: 'NAME',
  COMPANY: 'COMPANY',
  DESCRIPTION: 'DESCRIPTION',
  PERMISSION: 'PERMISSION',
};

export const ALL_READ_PERMISSIONS = [
  'dispatcher.orders.read',
  'dispatcher.partners.read',
  'dispatcher.reports.read',
  'dispatcher.manage.read',
  'dispatcher.items.read',
  'dispatcher.explore.read',
  'dispatcher.network.read',
  'dispatcher.invoices.read',
  'dispatcher.create_orders.read',
  'dispatcher.dashboard.read',
  'dispatcher.notifications.read',
  'dispatcher.chat.read',
];

export const ALL_WRITE_PERMISSIONS = [
  'dispatcher.orders.write',
  'dispatcher.partners.write',
  'dispatcher.reports.write',
  'dispatcher.network.write',
  'dispatcher.items.write',
  'dispatcher.explore.write',
  'dispatcher.manage.write',
  'dispatcher.invoices.write',
  'dispatcher.create_orders.write',
  'dispatcher.dashboard.write',
  'dispatcher.notifications.write',
  'dispatcher.chat.write',
];

export const MUST_HAVE_READ_PERMISSIONS = [];

export const DISABLED_WRITE_PERMISSIONS = [
  'dispatcher.reports.write',
  'dispatcher.dashboard.write',
  'dispatcher.notifications.write',
  'dispatcher.chat.write',
];
