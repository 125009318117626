import { t } from 'i18next';
import React, { useMemo } from 'react';

import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import Checkbox from '@yojee/ui/base/furnitures/Checkbox';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';

import {
  ALL_READ_PERMISSIONS,
  ALL_WRITE_PERMISSIONS,
  CONST_VARIABLE,
  DISABLED_WRITE_PERMISSIONS,
  MUST_HAVE_READ_PERMISSIONS,
} from './constants';

function createData(name, checkBoxRead, checkBoxEdit) {
  return { name, checkBoxRead, checkBoxEdit };
}

const useRow = ({ data, permissions, setPermissions, setErrorHelper }) => {
  const partnersEnabled = useIsFeatureEnabled(FEATURES_MAP.ENABLE_PARTNERS);

  // The data of must have read permission might be false or true but we will set it to true
  // when clicking the save button so doesn't need to check it in all read permission
  const isAllReadPermissionsSelected = ALL_READ_PERMISSIONS.filter(
    (permission) => !MUST_HAVE_READ_PERMISSIONS.includes(permission)
  ).every((permission) => permissions.includes(permission));

  const isAllWritePermissionsSelected = ALL_WRITE_PERMISSIONS.filter(
    (permission) => !DISABLED_WRITE_PERMISSIONS.includes(permission)
  ).every((permission) => permissions.includes(permission));

  const isAdmin = data?.name?.toLowerCase() === 'admin';

  const setSingleReadPermission = (readOnlyPermission) => {
    if (permissions.includes(readOnlyPermission)) {
      setPermissions(permissions.filter((permission) => permission !== readOnlyPermission));
    } else {
      setPermissions([...permissions, readOnlyPermission]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setSingleWritePermission = (readOnlyPermission, writeOnlyPermission) => {
    if (permissions.includes(writeOnlyPermission)) {
      setPermissions(
        permissions.filter((permission) => permission !== writeOnlyPermission && permission !== readOnlyPermission)
      );
    } else if (permissions.includes(readOnlyPermission)) {
      setPermissions([...permissions, writeOnlyPermission]);
    } else {
      setPermissions([...permissions, readOnlyPermission, writeOnlyPermission]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setAllReadPermission = () => {
    if (isAllReadPermissionsSelected) {
      setPermissions(
        permissions.filter((permission) => {
          const permissionSplit = permission.split('.');
          const haveWritePermission = permissions.includes(`${permissionSplit[0]}.${permissionSplit[1]}.write`);
          return (
            ALL_WRITE_PERMISSIONS.includes(permission) ||
            (ALL_READ_PERMISSIONS.includes(permission) && haveWritePermission)
          );
        })
      );
    } else {
      setPermissions([...new Set(permissions.concat(ALL_READ_PERMISSIONS))]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setAllWritePermission = () => {
    if (isAllWritePermissionsSelected) {
      setPermissions([]);
    } else {
      setPermissions(
        ALL_READ_PERMISSIONS.concat(ALL_WRITE_PERMISSIONS).filter(
          (permission) => !DISABLED_WRITE_PERMISSIONS.includes(permission)
        )
      );
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const rows = useMemo(() => {
    const rowsData = [];
    const featureFlags = {
      partners: partnersEnabled,
    };

    Object.entries({
      dashboard: t('Dashboard'),
      create_orders: t('Create orders'),
      explore: t('Explore page'),
      items: t('Items page'),
      orders: t('Orders page'),
      reports: t('Reports'),
      chat: t('Chat'),
      invoices: t('Invoices'),
      network: t('Network'),
      partners: t('Partners page'),
      notifications: t('Notifications'),
      manage: t('Manage'),
    })
      .filter(([permissionKey, _]) => !(permissionKey in featureFlags) || featureFlags[permissionKey])
      .forEach(([permissionKey, permissionLabel]) => {
        const readOnlyPermission = 'dispatcher.' + permissionKey + '.read';
        const writeOnlyPermission = 'dispatcher.' + permissionKey + '.write';
        const isMustHaveReadPermission = MUST_HAVE_READ_PERMISSIONS.includes(readOnlyPermission);
        const isDisableWritePermission = DISABLED_WRITE_PERMISSIONS.includes(writeOnlyPermission);
        const isNotificationPermission = permissionKey === 'notifications';

        rowsData.push(
          createData(
            permissionLabel,
            <Checkbox
              color="primary"
              checked={permissions.includes(readOnlyPermission) || isMustHaveReadPermission}
              onChange={() => setSingleReadPermission(readOnlyPermission)}
              disabled={
                !isNotificationPermission &&
                (isAdmin || permissions.includes(writeOnlyPermission) || isMustHaveReadPermission)
              }
            />,
            <Checkbox
              color="primary"
              checked={permissions.includes(writeOnlyPermission)}
              onChange={() => setSingleWritePermission(readOnlyPermission, writeOnlyPermission)}
              disabled={isAdmin || isDisableWritePermission}
            />
          )
        );
      });

    rowsData.unshift(
      createData(
        t('All'),
        <Checkbox
          color="primary"
          checked={isAllReadPermissionsSelected}
          onChange={setAllReadPermission}
          disabled={isAdmin || isAllWritePermissionsSelected}
        />,
        <Checkbox
          color="primary"
          checked={isAllWritePermissionsSelected}
          onChange={setAllWritePermission}
          disabled={isAdmin}
        />
      )
    );

    return rowsData;
  }, [permissions, data]);

  return { rows };
};
export default useRow;
