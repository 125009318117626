import { Trans } from 'react-i18next';

import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import getEnv from '@yojee/helpers/env/getEnv';

export const AVAILABLE_ORDER_STATUS_ENUM = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  PARTIAL_COMPLETED: 'partial_completed',
  CANCELLED: 'cancelled',
};
export const AVAILABLE_ORDER_STATUSES = [
  { id: AVAILABLE_ORDER_STATUS_ENUM.CREATED, text: <Trans>Created</Trans> },
  { id: AVAILABLE_ORDER_STATUS_ENUM.ACCEPTED, text: <Trans>Accepted</Trans> },
  { id: AVAILABLE_ORDER_STATUS_ENUM.COMPLETED, text: <Trans>Completed</Trans> },
  { id: AVAILABLE_ORDER_STATUS_ENUM.PARTIAL_COMPLETED, text: <Trans>Partially Completed</Trans> },
  { id: AVAILABLE_ORDER_STATUS_ENUM.CANCELLED, text: <Trans>Cancelled</Trans> },
];

export const ORDER_FILTERS = {
  DATE: {
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    ALL: 'all',
    SPECIFIC: 'specific',
  },
};

export const ORDER_FILTERS_KEY_TEXTS = {
  [ORDER_FILTERS.DATE.TODAY]: <Trans>Today</Trans>,
  [ORDER_FILTERS.DATE.TOMORROW]: <Trans>Tomorrow</Trans>,
  [ORDER_FILTERS.DATE.ALL]: <Trans>All</Trans>,
  [ORDER_FILTERS.DATE.SPECIFIC]: <Trans>Specific</Trans>,
};

export const ORDER_REVIEW_ITEMS_DIALOG_TYPES = {
  normal: 'normal',
  transfer: 'transfer',
};

export const getOrderIdentifier = (order) => {
  return order?.external_id || order?.number;
};

export const getDateWithoutTime = (target) => {
  const result = target ? new Date(target) : new Date();
  result.setHours(0, 0, 0, 0);
  return result;
};

export const addDay = (target, days) => {
  const _date = new Date(target);
  const result = new Date(_date.setDate(_date.getDate() + days));
  return new Date(result.getTime());
};

export const getDateRange = (type, from) => {
  if (type === ORDER_FILTERS.DATE.ALL) {
    return { from: null, to: null };
  }

  let _from = getDateWithoutTime();
  let _to = getDateWithoutTime();

  if (type === ORDER_FILTERS.DATE.TOMORROW) {
    _from = addDay(_from, 1);
  } else if (type === ORDER_FILTERS.DATE.SPECIFIC) {
    _from = from ? getDateWithoutTime(from) : _from;
  }
  _to = addDay(_from, 1);

  return { from: _from.toISOString(), to: _to.toISOString() };
};

const API_URL = getEnv('REACT_APP_API_URL');

export const getOrderInvoiceUrl = (orderNumberOrInvoiceRef) => {
  return `${API_URL}/public/invoices/${orderNumberOrInvoiceRef}`;
};

export const getOrderPOPUrl = (number, format = 'html') => {
  return `${API_URL}/public/pops/order/${number}?format=${format}`;
};

export const getOrderPODUrl = (number, format = 'html') => {
  return `${API_URL}/public/pods/order/${number}?format=${format}`;
};

export const getItemPODUrl = (itemTrackingNumber, format = 'pdf') => {
  return `${API_URL}/public/pods/order_item/${itemTrackingNumber}?format=${format}`;
};

export const getConsignmentNoteReportUrl = (number, format = 'html') => {
  return `${API_URL}/public/orders/${number}/consignment_note?format=${format}`;
};

export const getOrderLabelUrl = (number, format = 'html') => {
  return `${API_URL}/public/labels/order/${number}?format=${format}`;
};

const trackingPagePrefix = process.env.REACT_APP_ENV === 'eks' ? 'tracking' : 'track';
let TRACKING_URL = window.location.origin.replace(/(dispatcher)|(explore)/i, trackingPagePrefix);
const REACT_APP_TRACKING_URL = getEnv('REACT_APP_TRACKING_URL');

if (process.env.REACT_APP_IS_LOCAL === 'true' && REACT_APP_TRACKING_URL) {
  TRACKING_URL = REACT_APP_TRACKING_URL;
}
export const getOrderTrackingUrl = (trackingNumber, companySlug) => {
  const slug = companySlug ? companySlug : getSlugFromUrl();
  return slug && !TRACKING_URL.includes(`://${slug}.`)
    ? `${TRACKING_URL}/order/${trackingNumber}`.replace('://', `://${slug}.`)
    : `${TRACKING_URL}/order/${trackingNumber}`;
};
